import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Suspense } from "react";
import "./assets/css/style.css";
import "./App.css";
import Header from "./components/Header";
import Shop from "./pages/Shop";
import Product from "./pages/Product";
import AddNft from "./pages/AddNft";
import ProfileOwner from "./pages/ProfileOwner";
import EditProfileAdmin from "./pages/EditProfileAdmin";
import EditCollection from "./pages/EditCollection";
import SellNft from "./pages/SellNft";
import UserProfile from "./pages/UserProfile";
import ViewCollection from "./pages/ViewCollection";
import ViewUser from "./pages/ViewUser";
import AllCollection from "./pages/AllCollection";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import PrivacyPage from "./pages/PrivacyPage";
import TermPage from "./pages/TermPage";
import LegalPage from "./pages/LegalPage";
import EditProfile from "./pages/EditProfile";
import CreateSell from "./components/CreateSell";
import ComingSoon from "./pages/comingSoon";
import WhyUs from "./pages/WhyUs";
import WhyInvest from "./pages/WhyInvest";
import AuctionHouse from "./pages/AuctionHouse";

function App() {
  const profileData = useSelector((state) => state?.constants?.profileData);
  const loginState = useSelector((state) => state?.constants?.loginState);

  const publicRoutes = [
    { route: "/", comp: <Header /> },
    { route: "/marketplace", comp: <Shop /> },
    { route: "/privacy-policy", comp: <PrivacyPage /> },
    { route: "/terms-and-condition", comp: <TermPage /> },
    { route: "/legal", comp: <LegalPage /> },
    { route: "/working", comp: <CreateSell page /> },
    { route: "/art-swap", comp: <ComingSoon page /> },
    { route: "/why-us", comp: <WhyUs page /> },
    { route: "/auction-house", comp: <AuctionHouse page /> },
    { route: "/product/:id", comp: <Product /> },
    { route: "/collection/:id", comp: <ViewCollection /> },
    { route: "/why-invest-in-art", comp: <WhyInvest page /> },
    { route: "/interests", comp: <AllCollection /> },
  ]

  const privateRoutes = [
    { route: "/sell/:id", comp: <SellNft /> },
    { route: "/edit-profile/:id", comp: <EditProfileAdmin /> },
    { route: "/edit-collection/:id", comp: <EditCollection /> },
    { route: "/admin", comp: <UserProfile /> },
    { route: "/view-user/:id", comp: <ViewUser /> },
    { route: "/add-nft", comp: <AddNft /> },
  ]

  const PrivateOutlet = () => {
    const token =
      profileData?.data?.role === "admin" ||
      profileData?.data?.role === "creator";
    return loginState === 1 && token ? <Outlet /> : <Navigate to="/" />;
  };

  const UserProtect = () => {
    const token = profileData?.data?.role === "user";
    return loginState === 1 && token ? <Outlet /> : <Navigate to="/" />;
  };

  return (
    <>
      <Suspense fallback={"Loading..."}>
        <BrowserRouter>
          <Routes>
            {
              publicRoutes?.map(item => {
                return <Route key={item?.route} path={item?.route} element={item?.comp} />
              })
            }
            <Route element={<UserProtect />}>
              <Route path="/profile" element={<ProfileOwner />} />
              <Route path="/profile/:id" element={<EditProfile />} />
            </Route>
            <Route element={<PrivateOutlet />}>
              {
                privateRoutes?.map(item => {
                  return <Route key={item?.route} path={item?.route} element={item?.comp} />
                })
              }
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
