import React from "react";
import PRODUCT_IMG from "../assets/image/1.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import IMG from "../assets/image/nft1.jpeg";

const BannerCard = ({ data, home, isFetching, price }) => {

	console.log("datadata", data)
  return (
    <div className=" bg-white">
      <div className="sold-nft">
        {data?.token_owner?.remaining_fraction === 0 && (
          <div className="sold-box">
            <p>SOLD</p>
          </div>
        )}

        <div>
          <Link to={`/product/${data?._id}`} state={{ item: data }}>
            <img
              src={isFetching ? IMG : `${data?.nft_media[0]}`}
              // src={isFetching ? IMG : IMG}

              style={{
                opacity:
                  data?.token_owner?.remaining_fraction === 0 ? "0.3" : "1",
                height: "100%",
                maxHeight: "430px",
                width: home && "100%",
                objectFit: "contain",
                position: "relative",
              }}
              className="imgs cursor-pointer"
              alt
            />
          </Link>

          <span
            className="bg-green-600 px-4 py-1 text-white"
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
            }}
          >
            Featured
          </span>
        </div>
        <div
          style={{ textAline: "left" }}
          className="bg-white mt-0 text-left p-8"
        >
          <Link to={`/product/${data?._id}`} state={{ item: data }}>
            <h3 className="text-black text-[20px] bannerText font-bold txt">
              {data?.nft_name}
            </h3>
          </Link>
          <p className="text-xl mt-1">
            {/* By <span className="txt-light">Late Shri Sohan Qadri </span> */}
          </p>
          <p className="text-3xl font-bold mt-4 bannerText">$ {price}</p>
        </div>
      </div>
      {/* <button
				class="bg-gray-200 w-full p-4 text-3xl uppercase font-bold rounded-xl text-gray-700"
				onClick={() => navigate(`/product/${data?._id}`)}
			>
				View Detail
			</button> */}
    </div>
  );
};

export default BannerCard;
